import React from "react";
import "./LandingPage.css";
import giftimg from "../images/christmas-gifts.webp";
import moneyimg from "../images/christmas-money.webp";
import logo from "../images/logo.webp";

const LandingPage = () => {


    return (
        <div className="landing-page">
            <header className="hero">
                <img src={logo} alt="Clear Path Financial Services Logo" className="logoimg" />
                <h1>Go Ahead, Spend As Much As You Want For Christmas.</h1>
                <h2>CLEAR PATH FINANCIAL WILL HELP YOU PAY FOR IT ALL!</h2>
            </header>

            <main>
                <section className="intro">
                    <h2>Make This Holiday Season Magical</h2>
                    <p>
                        Tis the season for giving, not the season for stressing! Make your Christmas Merry,
                        even if your finances are hairy! we provide tailored financial solutions to make your 
                        holiday spending stress-free.
                    </p>
                    <img src={giftimg} alt="Christmas gifts under a tree" />
                </section>

                <section className="cta">
                    <h3>Start Your Journey to a Stress-Free Holiday</h3>
                    <iframe title="submit" width="100%" height="850" src="https://24c02ea8.sibforms.com/serve/MUIFAMH5i3zRlkO3zfuqsi2NL2_9pTgVX8LPdTx6diIIxVGhxTGCEm4J2Q1F25GHnvjIpkGnZ8R-pNM3dvVkjPfdgUQmw_vV56b6AyfB2idLtUp3vGEVc5HxTXhCZKf0_YOBwrFqPMrBUNP9G2hjXpzDmORCOzkacfwv-xeJa24XapAYetdJgoNLWtBF11zziRO5uSA7RDlkXkNs" frameborder="0"  allowfullscreen ></iframe>                </section>

                <section className="benefits">
                    <img src={moneyimg} alt="Christmas decorations with dollar bills" />
                    <p>Make This Christmas the Best One Yet with help from Clear Path Financial.</p>
                </section>
            </main>

            <footer>
                <nav className="social-links">
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">LinkedIn</a>
                </nav>
            </footer>
        </div>
    );
};

export default LandingPage;
